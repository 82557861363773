import request from "@/config/serve.js";

// 查询详情
export function queryDetails(params) {
  return request("get", "/claimReview/queryDetails", params);
}

// 查询语言列表
export function getLanguageList(params) {
  return request("get", "/language/all", params);
}

//认领审核查询
export function getClaimList(params) {
  return request("post", "/claimReview/queryClaimReviewList", params);
}
//认领资质查询
export function getqualificationList(params) {
  return request("post", "/claimReview/queryQualificationInformation", params);
}
//认领资质保存
export function savequalificationList(params) {
  return request("post", "/claimReview/updateReleaseInfo", params);
}
